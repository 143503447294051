.TransactionComponentTableContainer {
    display: flex;
    justify-content: center;
    overflow: auto;
    max-height: 60vh;
}

.TransactionComponentTable {
    width: 90%;
    height: 100%;
    border-radius: 2rem;
    border-spacing: 1rem 0.8rem;
    background-color: rgb(21, 34, 19);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,1);
}

.TransactionComponentTable > thead {
    position: sticky;
    background-color: white;
    margin-bottom: 1rem;
}

.TransactionTableColumnHeader {
    background-color: rgb(16, 16, 180);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,1);
    color: white;
    border: 2px solid rgb(253, 253, 253);
    border-radius: 1rem;
    padding: 0.5rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

.TransactionTableCell {
    padding: 0.3rem 0.1rem;
    border-radius: 1rem;
    margin: 2rem;
    background-color: white;
    /* min-width: 5rem; */
    max-width: 10rem;
    max-height: 20px;
    overflow: hidden;
}

.TransactionTableRecordRow > * {
    font-weight: 500;
    cursor: pointer;
    box-sizing: border-box;
    text-shadow: white;
    border: 2px solid white;
}

.TransactionTableRecordRow[data-transaction-status="200"] > * {
    box-shadow: 1px -1px 107px 11px rgba(0,255,0,0.84) inset;
}

.TransactionTableRecordRow[data-transaction-status="300"] > * {
    box-shadow: 1px -1px 107px 11px rgba(255,255,0,0.84) inset;
}


.TransactionTableRecordRow[data-transaction-status="401"] > * {
    box-shadow: 1px -1px 107px 11px rgba(255,0,0,0.84) inset;
    color: white;
    text-shadow: black;
}
