.Login {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.LoginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}



.LoginContainer > h1 {
    color: navy;
    margin-bottom: 2rem;
}


.LoginContainerForm > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}


.LoginContainerForm input {
    border: 1px solid blue;
    border-radius: 2rem;
    margin-bottom: 1rem;
    padding: 0.4rem 1rem;
    width: 30vw;
    height: 1.1rem;   
    font-size: 0.9rem;
    text-align: center;
}


.LoginContainerForm input:focus-within {
    background-color: rgba(213, 216, 255, 0.28);
}


.LoginContainerForm input[type='submit'] {
    background-color: rgb(16, 16, 180);
    color: white;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.1rem 1rem;
    margin-top: 2rem;
    line-height: 0;
    width: 10rem;
    height: 2rem;
    border: transparent;
    border-radius: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center,
    align
}


.LoginContainerForm input[type='submit']:hover {
    background-color: white;
    border: 1px double rgb(16, 16, 180);
    color: rgb(16, 16, 180);
}


.LoginContainerExtra {
    border-top: 1px dotted black;
    margin-top: 2rem;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}


.LoginContainerExtra > span {
    margin-bottom: 0.6rem;
    font-size: 0.7rem;
    font-weight: 200;
}

.LoginContainerExtra > span:nth-last-child(1) {
    margin-bottom: 0;
    font-weight: 700;
}

.LoginContainerExtra > span > a {
    text-decoration: none;
    color: black;
}



@media screen and (max-width: 700px) {

    .LoginContainerForm input {
        width: 70vw;
        margin-bottom: 0.5rem;
        font-size: 0.7rem;
    }

}