.AddorderComponent {
    text-align: center;
    overflow: auto;
    height: 60vh;
}

.AddorderComponent > h1 {
    color: white;
    background-color: rgb(16, 16, 180);
    margin: 1rem auto;
    padding: 0.7rem 0;
    border-radius: 2rem;
    position: sticky;
    top: 0;
}

.AddorderComponentForm {
    max-height: 80vh;
    display: flex;
    /* overflow: auto; */
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
}

.AddorderComponentForm > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    margin: 0.5rem 1rem;
}

.AddorderComponentForm div label {
    flex: 1;
    background-color: rgb(16, 16, 180);
    color: white;
    padding: 0.5rem 0.3rem;
    font-size: 1.2rem;
    border-radius: 2rem;
}

.AddorderComponentForm div input, .AddorderComponentForm div select  {
    flex: 3;
    padding: 0.7vw 0;
    text-align: center;
    border: 2px solid black;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 300;
}

.AddorderSubmitButton {
    background-color: rgb(16, 16, 180);
    color: white;
    padding: 0.5rem 0;
    margin: 0 auto;
    margin-top: 2rem;
    text-align: center;
    border-radius: 2rem;
    width: 20vw;
    font-size: 1.2rem;
    font-weight: 800;
    cursor: pointer;
}

.AddorderSubmitButton:hover {
    background-color: white;
    color: rgb(16, 16, 180);
}


.row {
    display: flex;
}

.col {
    flex: 1;
    display: flex;
    column-gap: 1rem;
}




@media screen and (max-width: 700px) {

    .AddorderComponent {
        height: 70vh;
    }
    
    .AddorderComponentForm {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
    }
    
    .AddorderComponentForm > div {
        display: flex;
        column-gap: 0.5rem;
        margin: 0.2rem 0;
    }
    
    .AddorderComponentForm div label {
        flex: 1;
        border: 0;
        background-color: rgb(16, 16, 180);
        color: white;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;
        font-size: 0.7rem;
        border-radius: 1rem;
    }
    
    .AddorderComponentForm div input, .AddorderComponentForm div select  {
        flex: 3.5;
        padding: 0.2rem;
        text-align: center;
        border: 2px solid black;
        border-radius: 2rem;
        font-size: 0.7rem;
        font-weight: 300;
    }
    
    .AddorderSubmitButton {
        background-color: rgb(16, 16, 180);
        color: white;
        padding: 0.5rem 0.2rem;
        margin: 2.5rem auto;
        text-align: center;
        border-radius: 2rem;
        width: 15rem;
        font-size: 0.8rem;
        font-weight: 800;
        cursor: pointer;
    }
    
    .AddorderSubmitButton:hover {
        background-color: white;
        color: rgb(16, 16, 180);
    }
    
    .row {
        column-gap: 0.5rem;
    }

    .col {
        column-gap: 0.2rem;
    }

    .ppc-and-platform:nth-child(1) {
        flex: 1;
    }

    .ppc-and-platform:nth-last-child(1) {
        flex: 3;
    }

    .col > label {
        flex: 1;
    }

    .col > input, .col > select {
        flex: 3;
        padding: 0;
        width: 3rem;
    }

    .col > input {
        width: 2rem;
    }
    

}