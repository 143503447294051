.LoginPage {
    height: 70vh;
    max-height: 70vh;
}


.LoginPageFormSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginPageFormSection h2 {
    font-size: 2rem;
    color: rgb(16, 16, 180);
}


@media screen and (max-width: 700px) {
    .LoginPageFormSection {
        flex-direction: column;
    }
}