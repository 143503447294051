.Topbar {
    background-color: white;
    position: sticky;
    height: 2.5rem;
    padding:0.8rem 0.3rem;
    margin: 0;
    line-height: 0;
    top: 0;
    left: 0;
    max-width: calc(100vw-0.3rem);
}


.TopbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.TopbarLogo {
    font-size: 2rem;
    font-weight: 700;
    color: rgb(16, 16, 180);
    margin-left: 1rem;
}

.TopbarContainerRight {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.TopbarButton {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid blue;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 1px 15px;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: rgb(16, 16, 180);
}


.text-link {
    color: inherit;
    text-decoration: inherit;
}


.LinkBadge {
    margin-right: 0.3rem;
}


@media screen and (max-width: 700px) {

    .TopbarContainer {
        margin: 0.2rem auto;
    }

    .TopbarLogo {
        font-size: 6vw;
        margin-left: 2px;
    }

    .TopbarButton {
        font-size: 3vw;
        padding: 1.5vw 2vw;
        margin: 0;
        margin-left: 1.5vw;
        font-weight: 500;
    }
    
    .LinkBadge {
        margin-right: 1vw;
    }

}