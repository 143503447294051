.OrderComponentTableContainer {
    text-align: center;
    width: 100%;
    max-height: 60vh;
    overflow: auto;
    font-size: 1rem;
    display: flex;
    justify-content: center;
}

.OrderComponentTable {
    width: 80%;
    height: 100%;
    border-radius: 2rem;
    border-spacing: 1rem 0.8rem;
    background-color: rgb(21, 34, 19);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,1);
}

.OrderComponentTable > thead {
    position: sticky;
    top: 1rem;
    background-color: white;
    margin-bottom: 2rem;
}

.OrderTableColumnHeader {
    background-color: rgb(16, 16, 180);
    color: white;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,1);
    border: 2px solid rgb(253, 253, 253);
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-weight: 900;
}



.OrderTableCell {
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    margin: 2rem;
    background-color: white;
    min-width: 2rem;
    height: 1rem;
    max-height: 1rem;
    box-sizing: border-box;
    overflow: hidden;
}