.SubscriptionComponent {
    padding: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.SubscriptionComponentHeading {
    color: rgb(16, 16, 180);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 5px solid rgb(16, 16, 180);
    display: flex;
    justify-content: space-between;
}

.SubscriptionComponentHeading > button {
    background-color: rgb(16, 16, 180);
    color: white;
    border: 4px solid rgb(16, 16, 180);
    font-size: 1rem;
    font-weight: 800;
    padding: 0.3rem 0.8rem;
    border-radius: 2rem;
    cursor: pointer;
}

.SubscriptionComponentHeading > button:hover {
    background-color: white;
    color: rgb(16, 16, 180);
}

