.Sidebar {
    height: 80vh;
    flex: 1;
    padding: 1.5rem 2rem;
    overflow: auto;
    -webkit-box-shadow: 0px 0px 21px -5px #000000; 
    box-shadow: 0px 0px 21px -5px #000000;
    border-radius: 0.5rem;   
}

.SidebarChildHeading {
    margin-bottom: 1rem;
    color: rgb(16, 16, 180);
}

.SidebarChildLink {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    color: rgba(2, 2, 255, 0.788);
}

.SidebarChildLink > span {
    margin-left: 0.5rem;
    padding: 0.2rem 0.5rem;
    text-decoration: none;
    color: inherit;
    font-size: 0.8rem;
    font-weight: 500;
}

.SidebarChildLink > span:hover {
    font-size: 0.75em;
    background-color: blue;
    color: white;
    border-radius: 0.8rem;
    cursor: pointer;
}

.SidebarChildLink > span:focus {
    border-radius: 0.8rem;
    background-color: blue;
    color: white;
}


.line-break {
    width: 60%;
    left: 12px;
    position: relative;
    height: 0.5px;
    margin-bottom: 0.8rem;
    border: 1px groove black;
}


/* --------------------
Profile Component
-------------------- */







/* --------------------
Service Component
-------------------- */

.SidebarService {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid black;
}
