.ProfileSidebar {
    flex: 1;
    /* height: 80vh; */
    padding: 1.5rem 2rem;
    overflow: auto;
    -webkit-box-shadow: 0px 0px 21px -5px #000000; 
    box-shadow: 0px 0px 21px -5px #000000;
    border-radius: 0.5rem;
}

.ProfileSidebarHeading {
    font-size: 2vw;
    color: rgb(16, 16, 180);
    padding-bottom: 0.7vw;
}

.ProfileSidebar > ul > li {
    list-style: none;
    font-size: 1.5vw;
    font-weight: 600;
    margin-top: 1.2rem;
    padding: 0.3vw 0.5vw;
    color: rgba(2, 2, 255, 0.788);
    cursor: pointer;
    width: 70% !important;
    max-width: fit-content;
}

.ProfileSidebar > ul > li:hover {
    background-color: rgb(16, 16, 180);
    color: white;
    border-radius: 2rem;
}





@media screen and (max-width: 750px) {

    .ProfileSidebar {
        display: none;
    }

    .ProfileSidebarHeading {
        font-size: 1.5rem;
        text-align: center;
        color: rgb(16, 16, 180);
    }
 
    .ProfileSidebar > ul > li {
        font-size: 1rem;
        margin: 0.5rem auto;
        padding: 0.3rem 0.6rem;
        text-align: center;
    }

}