.App {
  background-color: white;
  overflow:auto;
  margin-bottom: 0;
  height: 100vh;
}

.AppContainer {
  display: flex;
  column-gap: 1rem;
}

link {
  text-decoration: none;
  color: inherit;
}

html ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(16,16,180, 0.5); 
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: blue;
  box-shadow: inset 0 0 6px rgb(16,16,180); 
}