.RightcontainerProfileComponent {
    flex: 5;
    background: linear-gradient(to bottom,  rgba(240,249,255,1) 11%,rgba(203,235,255,1) 95%,rgba(203,235,255,1) 100%);
    padding: 1.5vw 2vw;
    border-radius: 0.5rem;
    /* height: calc; */
    height: calc(100vh-2.5rem);
    max-height: calc(100vh-2.5rem);
    /* max-height: 80vh; */
    overflow: auto;
    -webkit-box-shadow: 0px 0px 21px -5px #000000; 
    box-shadow: 0px 0px 21px -5px #000000;
}