.ProfilePage {
    display: flex;
    column-gap: 1vw;
    max-height: calc(100vh-2.5rem);
    height: calc(100vh-2.5rem);
    border: 2px solid red;
    overflow: hidden;
    padding: 1vw;  
}


@media screen and (max-width: 750px) {
    .ProfilePage {
      flex-direction: column;
    }
}