.ProfileComponent {
    border-radius: 2rem;
    flex: 4;
    padding: 2rem;
    overflow-x: hidden;
    height: calc(80vh - 4rem);
    max-height: calc(80vh - 4rem);
    
    background: linear-gradient(to bottom,  rgba(240,249,255,1) 11%,rgba(203,235,255,1) 95%,rgba(203,235,255,1) 100%);
}

.ProfileComponentHeading {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    color: rgb(16, 16, 180);
    border-bottom: 10px solid rgb(16, 16, 180);
}

.ProfileComponentHeading > h2 {
    margin-right: 1rem;
}

.ProfileComponentContent {
    display: flex;
}

.ProfileComponentLeftSection {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileComponentUserPictureDiv {
    display: flex;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.ProfileComponentUserPicture {
    width: 20vh !important;
    height: 20vh;
    margin-bottom: 2rem;
    font-size: 4rem;
    font-weight: 900;
    font-family: cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: teal;
    color: white;

    border: 5px solid white;
}

.ProfileComponentUserdetail {
    flex: 1.5;
    padding: 1rem 2rem;
    width: 30vw;
}

.ProfileComponentUserdetail > div {
    margin-top: 1rem;
    display: flex;
    column-gap: 20px;   
}

.ProfileComponentUserdetail > div > label {
    flex: 1;
    font-size: 1.1rem;
    font-weight: 700;
    background-color: rgb(16, 16, 180);
    color: white;
    border-radius: 2rem;
    padding: 0.5rem 2.5rem;
}

.ProfileComponentUserdetail > div > input {
    flex: 2;
    padding: 0.5rem 0.7rem;
    background-color: white;
    color: black;
    font-size: 0.95rem;
    font-weight: 400;
    border-radius: 1rem;
    text-align: center;
    height: 17px;
}



.ProfileComponentUserdetail > div > input:focus {
    background-color: rgba(112, 234, 236, 0.231);
    color: rgb(16, 16, 180);
}





/* ---------------------------------------
            FOR SMALL DEVICES
------------------------------------------ */


@media screen and (max-width: 750px) {
    .ProfileComponent {
        padding: 1rem;
        height: calc(84vh - 2rem);
        max-height: calc(84vh - 2rem);
        width: 80vw;
    }
    
    .ProfileComponentHeading {
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        color: rgb(16, 16, 180);
    }
    
    .ProfileComponentHeading > h2 {
        margin-right: 1rem;
    }
    
    .ProfileComponentContent {
        display: inline;
    }
    
    .ProfileComponentLeftSection {
        align-items: center;
        justify-content: center;
    }
    
    
    .ProfileComponentUserPictureDiv {
        border-right: 0px solid blue;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    
    .ProfileComponentUserPicture {
        width: 25vw !important;
        height: 25vw;
        margin-bottom: 0;
        font-size: 3rem;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: teal;
        color: white;
    }

    .ProfileComponentUserdetail {
        padding: 1rem 1.5rem;
        width: 80vw;
        display: inline;
    }
    
    .ProfileComponentUserdetail > div {
        margin-top: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;   
    }
    
    .ProfileComponentUserdetail > div > label {
        flex: 1;
        font-size: 0.8rem;
        padding: 0.3rem 0;
        padding-left: 0.5rem;
    }
    
    .ProfileComponentUserdetail > div > input {
        flex: 1.5;
        font-size: 0.8rem;
        font-weight: 400;
        width: 40vw;
        padding: 0.1rem 0.4rem;
    }

}