.ConsolePage {
  display: flex;
  column-gap: 1rem;
  max-height: 85vh;
  height: 85vh;
  overflow: hidden;
  padding: 1rem;
}


@media screen and (max-width: 750px) {
  .ConsolePage {
    flex-direction: column;
  }
}