.HomePage {
    width: 100vw;
    max-height: 90vh;
    overflow: auto;
}

.HomePage > img {
    width: 100vw;
    height: 89vh;
    max-height: 89vh;
    object-fit: cover;
}